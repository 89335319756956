<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-punto-gestion-export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar listado Puntos de Gestion
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="nombre" class="col-md-5">Nombre</label>
                    <input
                      type="text"
                      id="nombre"
                      v-model="filtros.nombre"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="ciudad_id" class="col-md-5">Ciudad</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ciudad"
                      placeholder="Ciudades"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.ciudades"
                      :filterable="true"
                      @input="getSelectCiudades()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="direccion" class="col-md-5">Dirección</label>
                    <input
                      type="text"
                      id="direccion"
                      v-model="filtros.direccion"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="telefono" class="col-md-5">Telefono</label>

                    <input
                      type="number"
                      id="telefono"
                      v-model="filtros.telefono"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="estado" class="col-md-5">Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="latitud" class="col-md-5">Latitud</label>
                    <input
                      type="number"
                      id="latitud"
                      v-model="filtros.latitud"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="longitud" class="col-md-5">Longitud</label>
                    <input
                      type="number"
                      id="longitud"
                      v-model="filtros.longitud"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="capacidad_parqueo" class="col-md-5"
                      >Capacidad de Parqueo</label
                    >
                    <input
                      type="number"
                      id="capacidad_parqueo"
                      v-model="filtros.capacidad_parqueo"
                      class="col-md-7"
                    />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombre1"
                              v-model="form.nombre"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombre1"
                              >Nombre</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="ciudad_id"
                              v-model="form.ciudad_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="ciudad_id"
                              >Ciudad</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="direccion1"
                              v-model="form.direccion"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="direccion1"
                              >Dirección</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="telefono1"
                              v-model="form.telefono"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="telefono1"
                              >Telefono</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="observacion"
                              v-model="form.observacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="observacion"
                              >Observación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Estado</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="justificacion_inactivacion"
                              v-model="form.justificacion_inactivacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="justificacion_inactivacion"
                              >Justificación de Inactivación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="latitud1"
                              v-model="form.latitud"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="latitud1"
                              >Latitud</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="longitud1"
                              v-model="form.longitud"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="longitud1"
                              >Longitud</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="capacidad_parqueo1"
                              v-model="form.capacidad_parqueo"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="capacidad_parqueo1"
                              >Capacidad de Parqueo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="link_video"
                              v-model="form.link_video"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="link_video"
                              >URL Video (YouTube)</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipos"
                              v-model="form.tipos"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="tipos"
                              >Lista Tipo Punto de Gestión
                            </label>
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipos_productos"
                              v-model="form.tipos_productos"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipos_productos"
                              >Lista Productos
                            </label>
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="procesos"
                              v-model="form.procesos"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="procesos"
                              >Lista Procesos
                            </label>
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="inspecciones"
                              v-model="form.inspecciones"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="inspecciones"
                              >Lista Inspecciones
                            </label>
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="sitios"
                              v-model="form.sitios"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="sitios"
                              >Lista Sitios Cargue y Descargue
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('admin.puntosGestion.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "PuntosGestionExport", //llegada tambien
  components: { vSelect, Loading },
  data() {
    return {
      cargando: false,
      ciudad: {},
      form: {
        nombre: true,
        ciudad_id: true,
        direccion: true,
        telefono: true,
        observacion: true,
        estado: true,
        justificacion_inactivacion: true,
        latitud: true,
        longitud: true,
        capacidad_parqueo: true,
        link_video: true,
        tipos: true,
        tipos_productos: true,
        procesos: true,
        inspecciones: true,
        sitios: true,
      },
      filtros: {
        nombre: null,
        ciudad_id: null,
        direccion: null,
        telefono: null,
        estado: null,
        latitud: null,
        longitud: null,
        capacidad_parqueo: null,
      },
      listasForms: {
        ciudades: [],
        estados: [],
      },
    };
  },

  methods: {
    getSelectCiudades() {
      this.filtros.ciudad = {};
      this.filtros.ciudad_id = null;
      if (this.ciudad) {
        this.filtros.ciudad = this.ciudad;
        this.filtros.ciudad_id = this.ciudad.id;
      }
    },
    async getCiudades() {
      await axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },
    async getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/puntosGestion/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getCiudades();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
